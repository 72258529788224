<template>
  <button class="btn btn-primary connect-btn" @click="$emit('click')">
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.188.4a.71.71 0 01.712.711v9.956a.71.71 0 11-1.423 0V1.11A.71.71 0 0112.188.4zM9.345 2.533a.71.71 0 01.711.711v7.823a.71.71 0 11-1.422 0V3.244a.71.71 0 01.711-.71zM7.211 5.378v5.689a.71.71 0 11-1.423 0v-5.69a.71.71 0 111.423 0zM3.655 6.8a.71.71 0 01.711.711v3.556a.71.71 0 11-1.422 0V7.51a.71.71 0 01.711-.711zM1.522 9.644v1.423a.71.71 0 11-1.422 0V9.644a.71.71 0 111.422 0z" fill="#fff" fill-opacity=".8"/></svg>
    <span>{{ staticText.connectLabel }}</span>
  </button>
</template>

<script>
export default {
  name: "ConnectIntegrationBtn",
  data () {
    return {
      staticTextDefault: {
        connectLabel: "Connect"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.connect-btn {
  display: flex;
  align-items: center;
  padding: 10px !important;
  span {
    margin-left: 4px;
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
