<template>
  <button class="btn btn-secondary disconnect-btn" @click="$emit('click')">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 .6C3.48.6.6 3.48.6 7c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4C13.4 3.48 10.52.6 7 .6zm3.2 8.704l-.896.896L7 7.896 4.696 10.2 3.8 9.304 6.104 7 3.8 4.696l.896-.896L7 6.104 9.304 3.8l.896.896L7.896 7 10.2 9.304z" fill="#7F8197"/></svg>
    <span>{{ staticText.disconnectLabel }}</span>
  </button>
</template>

<script>
export default {
  name: "DisconnectIntegrationBtn",
  data () {
    return {
      staticTextDefault: {
        disconnectLabel: "Disconnect"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.disconnect-btn {
  display: flex;
  align-items: center;
  padding: 10px !important;
  span {
    margin-left: 4px;
    font-size: 12px;
    line-height: 14px;
    color: $slate80 !important;
  }
}
</style>
