<template>
  <button class="btn btn-secondary reconnect-btn" @click="$emit('click')">
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.525 10a.4.4 0 01-.317-.644l2.122-2.747a.4.4 0 01.632-.001l2.136 2.747a.4.4 0 01-.316.645h-.736a.4.4 0 00-.4.4v.55c0 1.572-1.315 2.85-2.932 2.85H4.851c-1.616 0-2.931-1.278-2.931-2.85v-.55c0-.22.179-.4.4-.4h1.154c.221 0 .4.18.4.4v.55c0 .524.439.95.977.95h5.863c.539 0 .977-.426.977-.95v-.55a.4.4 0 00-.4-.4h-.766zM5.476 3.92a.4.4 0 01.316.645L3.67 7.312a.4.4 0 01-.632 0L.902 4.567a.4.4 0 01.316-.646h.737a.4.4 0 00.4-.4v-.55c0-1.572 1.314-2.85 2.931-2.85h5.863c1.617 0 2.931 1.278 2.931 2.85v.55a.4.4 0 01-.4.4h-1.154a.4.4 0 01-.4-.4v-.55c0-.523-.439-.95-.977-.95H5.286c-.538 0-.977.427-.977.95v.55c0 .221.18.4.4.4h.767z" fill="#7F8197"/></svg>
    <span>{{ staticText.reconnectLabel }}</span>
  </button>
</template>

<script>
export default {
  name: "ReconnectIntegrationBtn",
  data () {
    return {
      staticTextDefault: {
        reconnectLabel: "Reconnect"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.reconnect-btn {
  display: flex;
  align-items: center;
  padding: 10px !important;
  span {
    margin-left: 4px;
    font-size: 12px;
    line-height: 14px;
    color: $slate80 !important;
  }
}
</style>
