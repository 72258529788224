<template>
  <div class="bao-swift container-fluid mt-3 mb-5">
    <div class="swift-header">
      <h1>{{ staticText.baoSwiftLabel }}</h1>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'bao-swift-description-info-icon'" v-b-tooltip.hover="" :title="staticText.baoSwiftTooltipText" v-bind:class="'info-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
    </div>
    <div class="swift-container">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <img
            id="chrome-logo"
            class="mr-2"
            src="@/assets/images/baoSwift/chromeLogo.png"
            svg-inline
          />
          <img
            id="chromium-logo"
            class="mr-2"
            src="@/assets/images/baoSwift/chromiumLogo.png"
            svg-inline
          />
          <img
            id="edge-logo"
            class="mr-2"
            src="@/assets/images/baoSwift/edgeLogo.png"
            svg-inline
          />
          <p class="mb-0 heading">{{ staticText.addBaoSwiftDescription }}</p>
        </div>
        <a
          :href="baoSwiftDownloadLink"
          target="_blank"
          class="add_extension"
        >
          {{ staticText.addLabel }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaoSwiftIntegration",
  data () {
    return {
      staticTextDefault: {
        baoSwiftLabel: "bao SWIFT Browser Extension",
        addBaoSwiftDescription: "Add the bao SWIFT browser extension for a smoother bao-experience.",
        addLabel: "Add",
        baoSwiftTooltipText: "With the browser extension bao SWIFT you can easily use bao alongside your CRM in the same tab, so that you don't need to switch between your CRM and bao-tab.",
        selectLabel: "Select"
      },
      baoSwiftDownloadLink: process.env.VUE_APP_BAO_SWIFT_DOWNLOAD_LINK
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.bao-swift {
  .swift-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: $black80;
      margin: 0;
    }
    .info-icon {
      cursor: pointer;
      margin-left: 8px;
    }
  }
  .swift-container {
    background-color: $white40;
    border-radius: 12px;
    padding: 15px 24px;
    font-size: 14px;
    line-height: 16px;
    color: $slate80;
    #chrome-logo,
    #chromium-logo,
    #edge-logo {
      width: 16px;
      height: 16px;
    }
    p {
      &.heading {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.16px;
      }
    }
    a {
      &.add_extension {
        font-size: 12px;
        line-height: 14px;
        cursor: pointer;
        color: $white;
        transition: 0.3s ease-in;
        text-decoration: none !important;
        background-color: $orange80;
        padding: 10px 16px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: $orange;
        }
      }
    }
  }
}
</style>
