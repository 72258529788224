<template>
  <div class="integrations container-fluid mt-3 mb-5">
    <div class="integrations__header">
      <h1>{{ integrationsLabel }}</h1>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'integrations-description-info-icon'" v-b-tooltip.hover="" :title="integrationsTooltipText" v-bind:class="'info-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
    </div>
    <!-- CounterpartList -->
    <base-list
      :items="integrations"
      :fields="getFields()"
      :sort-by-prop="sortBy"
      :sort-desc-prop="false"
      :slots="templates"
      :loading="loading"
      :hide-filter-control="true"
      :hide-title="true"
      :show-pagination="false"
    >
      <template #name="row">
        <div id="service-20231701" class="d-flex align-items-center">

          <div v-if="row.cell.integration_type=='calendar'" class="calendar-logo">
            <img
              id="calendar-integ-202408231234"
              width="30px"
              height="30px"
              :src="getItemIcon(row.cell.icon)"
              svg-inline
            />
          </div>
          <component
            v-else
            :is="getCrmLogo(row.cell.key)"
            class="crm-logo crm-logo__slate mr-2"
          />
          <span class='ml-2'>
            {{ row.cell.name }}
          </span>
        </div>
      </template>
      <template #status="row">
        <div id="status-20231701" class="integrations__status d-flex">
          <span
            v-if="row.cell.status==IntegrationStatusEnum.CONNECTED"
            class="integrations__status--success"
          >
            {{ staticText.connectedLabel }}
          </span>
          <span v-else-if="row.cell.status==IntegrationStatusEnum.NOT_CONNECTED">
            {{ staticText.notConnectedLabel }}
          </span>
          <span v-else class="integrations__status--error">
            {{ staticText.errorLabel }}
          </span>
        </div>
      </template>
      <template #actions="row">
        <div id="actions-20231701" class="d-flex align-items-center">
          <disconnect-integration-btn
            v-if="row.cell.status==IntegrationStatusEnum.CONNECTED"
            @click="disconnectIntegration(row.cell)"
          />
          <connect-integration-btn
            v-else-if="row.cell.status==IntegrationStatusEnum.NOT_CONNECTED"
            @click="connectIntegration(row.cell)"
          />
          <div v-else class="d-flex align-items-center">
            <reconnect-integration-btn
              class="mr-2"
              @click="reconnectIntegration(row.cell)"
            />
            <disconnect-integration-btn
              @click="disconnectIntegration(row.cell)"
            />
          </div>
          <auto-join-setting
            v-if="row.cell.integration_type=='calendar' && row.cell.status==IntegrationStatusEnum.CONNECTED"
            :autoJoinRule="row.cell.auto_join_bot_rule"
            :autoJoinBot="row.cell.auto_join_bot"
            :calendarType="row.cell.key"
          />
        </div>
      </template>
    </base-list>
  </div>
</template>

<script>
import BaseList from "../../base/BaseList.vue"
import ConnectIntegrationBtn from "./buttons/ConnectIntegrationBtn.vue"
import DisconnectIntegrationBtn from "./buttons/DisconnectIntegrationBtn.vue"
import ReconnectIntegrationBtn from "./buttons/ReconnectIntegrationBtn.vue"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"
import { IntegrationStatusEnum, CalendarTypeEnum } from "@/apps/user/integrations/constants"
import AutoJoinSettingModal from "@/apps/user/integrations/AutoJoinSettingModal.vue"
import AutoJoinSetting from "@/apps/user/integrations/AutoJoinSetting.vue"


export default {
  name: "ServicesAndIntegrations",
  mixins: [CrmLogoMixin],
  props: {
    integrationsLabel: {
      type: String,
      default: "Integrations"
    },
    integrationsTooltipText: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean, 
      default: false
    },
    integrations: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      sortBy: "status",
      templates: [
        { name: "name", field: "name" },
        { name: "status", field: "status" },
        { name: "actions", field: "actions" }
      ],
      totalItems: null,
      autoJoin: false,
      openAutoJoinModal: false,
      staticTextDefault: {
        serviceLabel: "Service",
        statusLabel: "Current Status",
        actionsLabel: "Actions",
        connectedLabel: "Connected",
        notConnectedLabel: "Not Connected",
        errorLabel: "Error"
      },
      IntegrationStatusEnum,
      CalendarTypeEnum
    }
  },
  components: {
    BaseList,
    DisconnectIntegrationBtn,
    ReconnectIntegrationBtn,
    ConnectIntegrationBtn,
    AutoJoinSettingModal,
    AutoJoinSetting
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  methods: {
    getItemIcon(icon){
      return require(`@/assets/svgs/${icon}.svg`)
    },
    getFields () {
      return [
        { key: "name", label: this.staticText.serviceLabel, sortable: true },
        { key: "status", label: this.staticText.statusLabel, sortable: true },
        { key: "actions", label: this.staticText.actionsLabel }
      ]
    },
    connectIntegration (item) {
      this.$emit("toggle-integration", item)
    },
    disconnectIntegration (item) {
      this.$emit("toggle-integration", item, false)
    },
    reconnectIntegration (item) {
      this.$emit("open-o-auth-window", item.code_url)
    }
  }
}
</script>

<style lang="scss" scoped>
.integrations {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    h1 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      color: $black80;
      margin: 0;
    }

    .info-icon {
      cursor: pointer;
      margin-left: 8px;
    }
  }
  &__status {
    font-size: 14px;
    line-height: 16px;
    color: $slate80;
    &--success {
      color: #1faa09;
    }
    &--error {
      color: #bc0000;
    }
  }
}

.crm-logo {
  width: 50px;
  height: 30px;
}

.calendar-row{
  margin-left: 6px;
  margin-right: 25px;
}
.calendar-logo{
  width: 50px;
  height: 30px;
  margin-left: 10px;
}

.toggle-btn {
  color: #D4D5DC;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.calendar-action{
  height: 32px;
}
</style>
